<template>
  <v-app>
    <div v-if="dataJson">
      <!--begin::Dashboard-->
      <div class="row">
        <div class="col-xl-12">
          <v-card
            class="mx-auto rounded-lg ecosistema-aut"
            elevation="10"
            height="100%"
            outlined
          >
            <v-card-title>
              <v-avatar size="56">
                <img alt="Autoconsumo" src="/media/cide/ecosistemas/ALM.png" />
              </v-avatar>
              <h1 class="ml-3 texto-ecosistema-alm">Almacenamiento</h1>
            </v-card-title>
            <v-card-text>
              <div class="row">
                <div class="col-xl-8" />
                <div class="col-xl-2">Hogares rurales con almacenamiento</div>
                <div class="col-xl-2">Empresas rurales con almacenamiento</div>
              </div>

              <div class="row">
                <div class="col-xl-8">
                  <span class="font-weight-medium titulo"
                    >Porcentaje de usuarios rurales con almacenamiento eléctrico
                    instalado 2021
                  </span>
                </div>
                <div class="col-xl-2">
                  <span class="dato font-weight-black dato"
                    >{{ dataJson[0].data.hogar }} %
                  </span>
                </div>
                <div class="col-xl-2">
                  <span class="dato font-weight-black dato"
                    >{{ dataJson[0].data.empresa }} %
                  </span>
                </div>
              </div>
              <v-row>
                <div class="col-xl-8">
                  <span class="font-weight-medium titulo"
                    >Porcentaje de usuarios rurales con almacenamiento eléctrico
                    instalado 2022
                  </span>
                </div>
                <div class="col-xl-2">
                  <span class="dato font-weight-black dato"
                    >{{ dataJson[1].data.hogar }} %
                  </span>
                </div>
                <div class="col-xl-2">
                  <span class="dato font-weight-black dato"
                    >{{ dataJson[1].data.empresa }} %
                  </span>
                </div>
              </v-row>
              <v-row>
                <div class="col-xl-8">
                  <span class="font-weight-medium titulo"
                    >Porcentaje de usuarios rurales con almacenamiento eléctrico
                    instalado 2023
                  </span>
                </div>
                <div class="col-xl-2">
                  <span class="dato font-weight-black dato"
                    >{{ dataJson[2].data.hogar }} %
                  </span>
                </div>
                <div class="col-xl-2">
                  <span class="dato font-weight-black dato"
                    >{{ dataJson[2].data.empresa }} %
                  </span>
                </div>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-6">
          <v-card class="mx-auto rounded-lg" elevation="10" outlined>
            <v-card-title>
              Porcentaje de usuarios rurales con almacenamiento eléctrico
            </v-card-title>
            <v-card-subtitle>
              % de población rural con almacenamiento eléctrico
            </v-card-subtitle>
            <v-card-text>
              <fusioncharts
                type="spain"
                width="100%"
                height="500"
                data-format="jsonurl"
                :data-source="'/data/ALM/nacional/mapa_inp03.json'"
              />
            </v-card-text>
          </v-card>
        </div>
        <div class="col-xl-6">
          <v-card class="mx-auto rounded-lg" elevation="10" outlined>
            <v-card-title>
              Porcentaje de usuarios rurales con intención de instalar/ampliar
              almacenamiento eléctrico
            </v-card-title>
            <v-card-subtitle>% de población rural</v-card-subtitle>
            <v-card-text>
              <fusioncharts
                type="msstackedcolumn2d"
                width="100%"
                height="500"
                data-format="jsonurl"
                :data-source="'/data/ALM/nacional/inp04.json'"
              />
            </v-card-text>
          </v-card>
        </div>
      </div>

      <!--end::Dashboard-->
    </div>
  </v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "Dashboard",
  components: {},
  data() {
    return {
      selectedVariable:
        "Contribución a la descarbonización del Vehículo Eléctrico",
      selectedYear: "2020",
      selectedOption: "Todos",
      dataJson: null,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
    this.getJsonData();
  },
  methods: {
    getJsonData() {
      fetch("/data/ALM/nacional/data.json")
        .then((response) => response.json())
        .then((data) => {
          this.dataJson = data;
        });
    },
  },
};
</script>
<style lang="scss">
@import "~vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}
</style>
